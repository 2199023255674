import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Workout 19.5`}</strong></p>
    <p>{`33-27-21-15-9 reps for time of:`}</p>
    <p>{`Thrusters (95/65)`}</p>
    <p>{`CTB Pullups`}</p>
    <p>{`Time cap: 20:00`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be judging during our normal class times this week and
throwing down from 4:00-7:00pm tonight!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule started this week!  Evening CrossFit classes
are now offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new Ville Bootcamp started this week as well!  These classes
will be Tues-Friday at 9:30am.  The rest of this month is free for non
members so spread the word if you know of anyone that may want to try it
out. This class is included in your unlimited membership. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      